import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import { Title } from '~/components/elements/Title'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useReviews from '~/hooks/useReviews'

export default function ContentCustomerReview({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const reviews = useReviews()
  const stars = []

  for (let i = 0; i < reviews?.totalRating; i++) {
    stars.push(i)
  }

  return (
    <section
      data-component="ContentCustomerReview"
      className={clsx(
        fields.background === '#E9EAEA' && 'section section--with-bg bg-site-grey',
        fields.background === '#393C42' && 'section section--with-bg bg-site-alt',
        fields.background === '#FFFFFF' && 'section bg-white',
        'relative overflow-hidden'
      )}
    >
      <div className="container relative">
        <LossePlaatjie
          maxwidth={2000}
          src={fields.image}
          className="relative max-sm:h-[300px] sm:absolute top-0 bottom-0 z-10 my-auto -left-[8%] sm:-left-[5%] h-[80%] max-w-[unset] object-cover w-[116%] sm:w-[110%] sm:rounded-[7px] shadow-site-shadow"
        />
        <div className="grid max-sm:-mt-10 grid-cols-4 sm:grid-cols-5 md:grid-cols-6 lg:grid-cols-9 relative z-20">
          <div className="col-span-4 sm:col-start-2 md:col-start-3 lg:col-start-6">
            <div className="bg-site-silver shadow-site-shadow rounded-[7px] p-5 xm:p-6 sm:p-10 lg:p-14 flex flex-col gap-3 sm:gap-6">
              <Title size="small">{fields.title}</Title>
              <div className="flex flex-col">
                <div className="flex items-center gap-3">
                  <p className="text-site-base font-semibold">Klanten beoordelen ons met een {reviews?.totalRating}</p>
                  <div className="flex items-center gap-[2px]">
                    {stars?.map((star, index) => (
                      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" key={index}>
                        <g clipPath="url(#clip0_1157_21)">
                          <path
                            d="M9.88209 1.60996C10.0529 1.19932 10.6346 1.19932 10.8054 1.60996L12.8715 6.57744C12.9435 6.75056 13.1063 6.86885 13.2932 6.88383L18.656 7.31376C19.0993 7.3493 19.2791 7.90256 18.9413 8.19189L14.8554 11.6919C14.713 11.8139 14.6509 12.0053 14.6944 12.1876L15.9427 17.4208C16.0459 17.8534 15.5752 18.1954 15.1957 17.9635L10.6044 15.1592C10.4444 15.0615 10.2431 15.0615 10.0831 15.1592L5.49181 17.9635C5.11226 18.1954 4.64163 17.8534 4.74483 17.4208L5.99314 12.1876C6.03664 12.0053 5.97445 11.8139 5.83206 11.6919L1.74616 8.19189C1.40839 7.90256 1.58816 7.3493 2.03148 7.31376L7.39429 6.88383C7.58119 6.86885 7.74399 6.75056 7.816 6.57744L9.88209 1.60996Z"
                            fill="#42BD3B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1157_21">
                            <rect width="20" height="20" fill="white" transform="translate(0.34375)" />
                          </clipPath>
                        </defs>
                      </svg>
                    ))}
                  </div>
                </div>

                <a
                  target="_blank"
                  rel="noreferrer"
                  className="underline md:block hidden"
                  href="https://www.google.com/search?sca_esv=ed3fcdd2868a633a&sxsrf=AHTn8zodl7i_kcjTCgeKlIK3kwSbAfU_bw:1741851989727&uds=ABqPDvztZD_Nu18FR6tNPw2cK_RRgPocZNJgYutdBYynNDH3jpcXOfqLb7iEAS7ZJTzVV3gtZeyj4-Q4rWE_5fpdhERWSO6GzFBheU2OPgfYJCSEPx-JhkZi64SvNpiwMwjCX_QVB8LHugEGSXmXfIK81LIRHddA7Il5VR6fsswBxI2yf7ambbo&si=APYL9bvoDGWmsM6h2lfKzIb8LfQg_oNQyUOQgna9TyfQHAoqUuLQuHRh5_PSAHyHb5PB6tdtP6xawLqMIx1Kaiy3mshz8N9W4aRItpTHVCdvlM3VAvEiSQ8%3D&q=TOC+kantoorinrichting+Reviews&sa=X&ved=2ahUKEwjTh-WoyIaMAxW2gf0HHVEZEogQ3PALegQIShAF&biw=1470&bih=730&dpr=2"
                >
                  Bekijk hier alle reviews
                </a>
              </div>
              <Content>{fields.description}</Content>
              <div className="font-bold text-lg">{fields.subtitle}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
